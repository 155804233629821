import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pageTitle"
import { StaticImage } from "gatsby-plugin-image"
import { H3, H4 } from "../components/heading"
import { Link } from "gatsby"

function Corona() {
  return (
    <Layout>
      <Seo title="Corona" path="/corona/" />
      <PageTitle>Corona</PageTitle>
      <ul className="mb-5 list-disc list-inside">
        <li>
          <a href="#hygienemaßnahmen" className="underline">
            Hygienemaßnahmen
          </a>
        </li>
        <li>
          <a href="#test-impf-station" className="underline">
            Corona Test- und Impfstation
          </a>
        </li>
      </ul>
      <div className="prose prose-lg mb-8">
        <p className="">
          Als verantwortungsvolle Veranstalter*innen liegt uns die Gesundheit
          unserer Besucher*innen sehr am Herzen.{" "}
          <strong>
            Deshalb haben wir ein Hygienekonzept erarbeitet, das wir
            gewissenhaft mit den Vorgaben der Stadt Dachau und dem Landkreis
            Dachau abstimmen.
          </strong>{" "}
          Dieses ausführliche Hygienekonzept wird fortlaufend aktualisiert und
          an die aktuellen Inzidenzwerte und Verordnungen der bayrischen
          Staatsregierung angepasst.
        </p>
        <p>
          Es gibt einen abgegrenzten Bereich auf der Ludwig-Thoma-Wiese mit
          einem großen Zirkuszelt mit geringer Auslastung (
          <strong>kultARENA</strong>). So planen wir derzeit mit nur 180 statt
          der 750 erlaubten Personen/Sitzplätze im Veranstaltungszelt, um die
          geltenden Abstandsregeln gewährleisten zu können.
        </p>
        <p>
          Wir verfügen über einen großen Außenbereich (<strong>OPEN AIR</strong>
          ) mit Biergarten, Galeriezelt und Sinnesgarten. Die Food Trucks und
          Bars werden so platziert, dass beim Anstellen ebenfalls der geforderte
          Mindestabstand eingehalten werden kann.
        </p>
      </div>
      <H3 id="hygienemaßnahmen" className="mb-6">
        Hygienemaßnahmen
      </H3>
      <p className="mb-6">
        Im Sinne aller Besucher*innen bitten wir alle Menschen sich an die
        folgenden Corona Schutzmaßnahmen zu halten und verweisen an dieser
        Stelle nochmal auf unsere{" "}
        <Link to="/hausordnung" className="underline">
          Hausordnung
        </Link>
        .
      </p>
      <div className="bg-yellow-100 p-4 mb-5">
        <H4 className="mb-3">kultARENA</H4>
        <ul className="list-disc list-inside mb-2">
          <li>
            Es gilt der <strong>3G Grundsatz</strong> (geimpft, genesen oder
            getestet)
          </li>
          <li>
            <strong>Maskenpflicht</strong>¹ im Zelt (darf auf Sitzplätzen
            abgenommen werden)
          </li>
          <li>
            Kontaktnachverfolgung in Papierform oder digital (Corona Warn App)
          </li>
          <li>Freie Sitzplatzwahl draußen und im Zelt</li>
        </ul>
        <ul className="text-base">
          <li>
            ¹ Auf offenem Gelände gilt keine Maskenpflicht, wir bitten jedoch
            alle Besucher*innen in Warteschlangen Masken zu tragen und auf
            Abstand zu achten!
          </li>
        </ul>
      </div>
      <div className="bg-purple-100 p-4 mb-12">
        <H4 className="mb-3">OPEN AIR</H4>
        <ul className="list-disc list-inside mb-2">
          <li>
            Maximale Anzahl Besucher*innen: <strong>640</strong>¹
          </li>
          <li>
            Kontaktnachverfolgung in Papierform oder digital (Corona Warn App)
          </li>
          <li>
            Im Galeriezelt gilt der <strong>3G Grundsatz</strong> und{" "}
            <strong>Maskenpflicht</strong>²
          </li>
          <li>Freie Sitzplatzwahl</li>
        </ul>
        <ul className="text-base">
          <li>
            ¹ Vor Ort gibt es ein Ampelsystem, dass wir auch hier auf der
            Webseite verfügbar machen. So können Sie sich jederzeit online
            informieren ob es noch ausreichend Plätze gibt.
          </li>
          <li>
            ² Auf offenem Gelände gilt keine Maskenpflicht, wir bitten jedoch
            alle Besucher*innen in Warteschlangen Masken zu tragen und auf
            Abstand zu achten!
          </li>
        </ul>
      </div>
      <H3 id="test-impf-station" className="mb-5">
        Corona Test- und Impfstation
      </H3>
      <div className="prose prose-lg mb-5">
        <p>
          Aufgrund der aktuellen Situation rund um die Corona-Pandemie und um
          einen Beitrag zur Steigerung der Impfrate im Landkreis Dachau zu
          leisten, bieten wir{" "}
          <strong>
            in Zusammenarbeit mit dem BRK Dachau während des Festivals eine
            Teststation und eine mobile Impfstation
          </strong>{" "}
          zu folgenden Zeiten an:
        </p>
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <strong>Öffnungszeiten Teststation</strong>
            <ul>
              <li>Mittwoch, 17:30 - 20:30 Uhr</li>
              <li>Donnerstag, 17:30 - 20:30 Uhr</li>
              <li>Freitag, 17:00 - 21:00 Uhr</li>
              <li>Samstag, 12:00 - 17:00 Uhr</li>
              <li>Sonntag, 12:00 - 17:00 Uhr</li>
            </ul>
          </div>
          <div>
            <strong>Öffnungszeiten Impfstation</strong>

            <ul>
              <li>Freitag, 16:00 - 19:30 Uhr</li>
              <li>Samstag, 14:00 - 19:00 Uhr</li>
              <li>Sonntag, 14:00 - 18:00 Uhr</li>
            </ul>
          </div>
        </div>
      </div>

      <StaticImage
        src="../images/covid-facilities.png"
        placeholder="none"
        layout="fullWidth"
        alt="Image showing covid facilities on kult'21 Festival"
        className="mb-5"
      />
      <div className="prose prose-lg">
        <p>
          Sicherheitspersonal und Ordner*innen überwachen die Einhaltung
          sämtlicher Hygienemaßnahmen auf dem Festivalgelände.
        </p>
        <p>
          Damit Sie sich auf dem <strong>kult’21</strong> nicht nur sicher
          sondern auch wohl fühlen erarbeitet unsere Arbeitsgruppe “Flair” ein
          Konzept um trotz der pandemiebedingten Einschränkungen das altbekannte
          und einmalige kultfeeling für all unsere Gäste spür- und erlebbar
          werden zu lassen.
        </p>
        <p>
          In diesem Sinne freuen wir uns darauf, Sie als Gast bei einem
          coronakonformen <strong>kult’21</strong> begrüßen zu dürfen und{" "}
          <strong>hoffen Sie bleiben bis dahin gesund</strong>.
        </p>
        <p>Mehr Informationen:</p>
        <ul>
          <li>
            <a
              href="https://www.landratsamt-dachau.de/gesundheit-veterinaerwesen-sicherheitsrecht/gesundheit/coronavirus/"
              target="_blank"
              rel="noreferrer"
            >
              Informationen zum Coronavirus
            </a>{" "}
            (LRA Dachau)
          </li>
          <li>
            <a
              href="https://www.landratsamt-dachau.de/gesundheit-veterinaerwesen-sicherheitsrecht/gesundheit/coronavirus/aktuelle-regelungen-fuer-den-landkreis/"
              target="_blank"
              rel="noreferrer"
            >
              Aktuelle Regelungen im Landkreis Dachau
            </a>{" "}
            (LRA Dachau)
          </li>
        </ul>
        <p>
          Im Falle einer Corona bedingten Absage des Festivals wird der
          Kaufpreis bis zu einer Frist von 2 Monaten nach der Absage auf Anfrage
          zurückerstattet. Die Rückabwicklung des Kaufpreises erfolgt über die
          beim Ticketkauf verwendete Zahlungsmethode. (siehe AGB)
        </p>
      </div>
    </Layout>
  )
}

export default Corona
