import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pageTitle"
import Container from "../components/container"
import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import cx from "classnames"
import { H3 } from "../components/heading"

const AvatarPlaceholder = ({ color }) => {
  const colors = ["red", "yellow", "green", "blue", "purple", "pink"]

  return (
    <span
      className={cx(
        "inline-block h-24 w-24 lg:h-28 lg:w-28 rounded-full overflow-hidden",
        {
          "bg-red-300": color === "red",
          "bg-yellow-300": color === "yellow",
          "bg-green-300": color === "green",
          "bg-blue-300": color === "blue",
          "bg-purple-300": color === "purple",
          "bg-pink-300": color === "pink",
          "bg-gray-300": !color || !colors.includes(color),
        }
      )}
    >
      <svg
        className="h-full w-full text-gray-100"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  )
}

export const query = graphql`
  {
    vereine: allVereineJson {
      nodes {
        id
        label
        website
        logo {
          id
          absolutePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    team: allTeamJson {
      nodes {
        id
        name
        color
        avatar {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              width: 120
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    helferLink: wpPost(title: { regex: "/tatkräftige/" }) {
      id
      title
      uri
    }
  }
`

const About = ({ data }) => {
  const vereine = data.vereine.nodes
  const team = data.team.nodes
  const helferLinkTo = data.helferLink.uri

  return (
    <Layout content="wide">
      <Seo title="Über uns" path="/ueber-uns/" />
      <PageTitle>Über uns</PageTitle>

      <Container className="mb-8">
        <p>
          Das kult’21 ist ein Gemeinschaftsprojekt ganz vieler Vereine und
          Einzelpersonen. Wir sind froh und dankbar für jeden Menschen der uns
          dabei hilft dieses Festival zu realisieren. Auf dieser Seite wollen
          wir euch diese Menschen und Institutionen vorstellen.
        </p>
      </Container>

      <Container size="wide" className="mb-16">
        <StaticImage
          src="../images/kult-arena-bastel-aktion.jpg"
          placeholder="blurred"
          className="mb-2 shadow-sm rounded"
        />
        <p className="text-center text-sm md:text-base text-gray-700">
          Ein Teil des fantastischen kult'21 Teams beim Basteln
        </p>
      </Container>

      <Container size="wide" className="mb-16">
        <H3 className="text-center mb-8">Unser Kernteam 💜</H3>
        <ul className="mb-8 mx-auto grid grid-cols-2 sm:grid-cols-3 gap-x-4 gap-y-8 md:grid-cols-4 md:gap-x-6 lg:gap-x-8 lg:gap-y-12 lg:grid-cols-6 justify-items-center">
          {team.map(person => {
            const image = getImage(person.avatar)

            return (
              <li key={person.id}>
                <div className="space-y-4">
                  {person.avatar ? (
                    <GatsbyImage
                      className="mx-auto h-24 w-24 rounded-full lg:w-28 lg:h-28"
                      image={image}
                      alt={person.name}
                      style={{ borderRadius: "9999px" }}
                      imgStyle={{ borderRadius: "9999px" }}
                      imgClassName="rounded-full"
                    />
                  ) : (
                    <AvatarPlaceholder color={person.color} />
                  )}
                  <div className="space-y-2">
                    <div className="text-sm font-bold lg:text-base text-center">
                      {person.name}
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </Container>

      <Container className="mb-16">
        <H3 className="text-center mb-8">Unsere Helfer*innen 💜</H3>
        <p className="mb-4 text-center">
          Das kult Festival ließe sich nicht ohne eine Vielzahl an
          ehrenamtlichen Helfer*innen realisieren. Wir sind froh um jede Person,
          die mit anpackt. Namentlich…
        </p>
        <p className="mb-4 text-center font-bold">
          Stefan, Frank, Alicia, Theresa, Marian, Corinna, Sophia, Bettina,
          Christa, Sebastian, Christine, Alexander, Susi, Luis, Sabine, Luca,
          Thomas, Markus, Doreen, Reini, Moritz, Franziska, Tobias, Akin,
          Nicolas, Jakob, Bernd, Tatiana, Michael, Mert, Annique, Gabriele,
          Vroni, Carsten, Armin, Lisa, Leo, Heikki, Christoph, Andi, Alois,
          Lucas, Sophia, Rita, Anna, Matthias, Mark, Anita, Johanna, Judith,
          Robert, Lisa, Sandra, Fernando, Katharina, Benedikt, Joshua, Evi,
          Stefan, Sascha, Ute, Claudia, Esther, Marlene, Carola, Theresa,
          Maximilian, Jona, Birgit, Isabella, Lisa, Barbara, Marie, Kurt,
          Sabrina, Iris, Anton, Simon
        </p>
        <p className="mb-4 text-center">…und es werden ständig mehr :)</p>
        <p className="mb-4 text-center font-bold">DANKE!</p>
        <p className="mb-4 text-center">
          Wenn du auch mithelfen möchtest, findest du{" "}
          <Link to={helferLinkTo} className="underline">
            hier
          </Link>{" "}
          mehr Infos.
        </p>
      </Container>

      <div>
        <div className="text-center mb-16">
          <H3 className="">Vereine & Institutionen</H3>
          <p className="text-gray-600">
            Organisation, Kooperation und Unterstützende
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 md:gap-16 justify-items-center">
          {vereine.map(verein => {
            const image = getImage(verein.logo)

            return (
              <a
                href={verein.website}
                target="_blank"
                rel="noreferrer"
                key={verein.id}
                className="transform hover:scale-125 transition-transform duration-150 ease-in-out"
                aria-label={verein.label}
              >
                <GatsbyImage image={image} alt={verein.label} />
              </a>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default About
