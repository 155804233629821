import React from "react"
import cx from "classnames"

const Container = ({ size, className, children, ...rest }) => {
  const classes = cx(
    {
      "max-w-3xl": !size || size === "default",
      "max-w-7xl": size === "wide",
    },
    "mx-auto",
    "px-4 sm:px-6 lg:px-8",
    className
  )
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  )
}

export default Container
