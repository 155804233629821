import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import H, { H3 } from '../components/heading'
import Layout from '../components/layout'
import PageTitle from '../components/pageTitle'
import Seo from '../components/seo'

const dayKeys = [
	{ key: 1509, label: 'Mittwoch, 15.09.', short: '15.09.' },
	{ key: 1609, label: 'Donnerstag, 16.09.', short: '16.09.' },
	{ key: 1709, label: 'Freitag, 17.09.', short: '17.09.' },
	{ key: 1809, label: 'Samstag, 18.09.', short: '18.09.' },
	{ key: 1909, label: 'Sonntag, 19.09.', short: '19.09.' },
]

const openingHours = {
	arena: {
		1509: '18:00 bis 23:00 Uhr',
		1609: '15:00 bis 23:00 Uhr',
		1709: '18:00 bis 24:00 Uhr',
		1809: '10:00 bis 24:00 Uhr',
		1909: ['09:30 bis 14:00 Uhr', '16:00 bis 22:00 Uhr'],
	},
	openAir: {
		1509: '18:00 bis 23:00 Uhr',
		1609: '18:00 bis 23:00 Uhr',
		1709: '16:00 bis 24:00 Uhr',
		1809: '10:00 bis 24:00 Uhr',
		1909: '10:00 bis 22:00 Uhr',
	},
}

const Hour = ({ hour, ...rest }) => (
	<div {...rest}>
		{Array.isArray(hour) ? (
			<ul>
				{hour.map(hour => (
					<li key={hour}>{hour}</li>
				))}
			</ul>
		) : (
			hour
		)}
	</div>
)

const OpeningHours = () => {
	return (
		<Layout>
			<PageTitle>Öffnungszeiten</PageTitle>
			<Seo title="Öffnungszeiten" />
			<p className="mb-6">
				Das <strong>kult'21</strong> besteht aus zwei abgetrennten Bereichen (
				<strong>kultARENA</strong> und <strong>OPEN AIR</strong>) mit unterschiedlichen
				Öffnungszeiten. Der Bereich für die <strong>Infostände</strong> und{' '}
				<strong>Workshops</strong> befindet sich außerhalb und ist zu den Zeiten des{' '}
				<strong>OPEN AIR</strong> Bereichs besetzt.
			</p>
			<StaticImage src="../images/openair-kultarena.png" className="mb-12" />
			<div className="hidden sm:grid sm:grid-cols-3 gap-x-2 gap-y-4">
				{/* legend */}
				<div className=""> </div>
				<div>
					<H as="h3" size={4}>
						kultARENA
					</H>
				</div>
				<div>
					<H as="h3" size={4}>
						OPEN AIR
					</H>
				</div>
				{dayKeys.map(({ key, label }) => (
					<React.Fragment key={key}>
						<div>{label}</div>
						<Hour hour={openingHours.arena[key]} />
						<Hour hour={openingHours.openAir[key]} />
					</React.Fragment>
				))}
			</div>
			<div className="sm:hidden mb-8">
				<H3 className="mb-4">kultARENA</H3>
				<div className="grid grid-cols-3 gap-2">
					{dayKeys.map(({ key, short }) => (
						<React.Fragment key={key}>
							<div>{short}</div>
							<Hour hour={openingHours.arena[key]} className="col-span-2" />
						</React.Fragment>
					))}
				</div>
			</div>
			<div className="sm:hidden">
				<H3 className="mb-4">OPEN AIR</H3>
				<div className="grid grid-cols-3 gap-2">
					{dayKeys.map(({ key, short }) => (
						<React.Fragment key={key}>
							<div>{short}</div>
							<Hour hour={openingHours.openAir[key]} className="col-span-2" />
						</React.Fragment>
					))}
				</div>
			</div>
		</Layout>
	)
}

export default OpeningHours
