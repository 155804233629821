import React from "react"
import { graphql, Link, navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pageTitle"
import H from "../components/heading"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const query = graphql`
  query {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        date(formatString: "D. MMMM YYYY", locale: "de")
        excerpt
        uri
        featuredImage {
          node {
            localFile {
              childImageSharp {
                id
                gatsbyImageData(width: 300)
              }
            }
          }
        }
      }
    }
  }
`

function Neuigkeiten(props) {
  const posts = props.data.allWpPost.nodes

  return (
    <Layout content="wide">
      <Seo title="News" path="/news/" />
      <PageTitle>News</PageTitle>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 text-gray-700">
        {posts.map(post => {
          const featuredImage = !!post.featuredImage
          const imageData =
            featuredImage && getImage(post.featuredImage.node?.localFile)
          return (
            <article
              key={post.id}
              className="group cursor-pointer"
              onClick={() => navigate(post.uri)}
            >
              <GatsbyImage
                className="w-full h-48 object-cover"
                image={imageData}
                alt={`Featured image for the article "${post.title}"`}
              />
              <span className="text-sm text-gray-700 my-2">{post.date}</span>
              <H as="h3" size={4} className="mb-2">
                {post.title}
              </H>
              <div
                className="text-base leading-relaxed mb-2"
                dangerouslySetInnerHTML={{ __html: post.excerpt }}
              />
              <Link
                to={post.uri}
                className="group-hover:underline hover:underline hover:text-primary text-base"
              >
                Weiterlesen
              </Link>
            </article>
          )
        })}
      </div>
    </Layout>
  )
}

export default Neuigkeiten
