import * as React from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { getImage, StaticImage, GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Sponsors from '../components/sponsors'
import Container from '../components/container'
import H, { H1, H2, H3 } from '../components/heading'
import ArtistSquare from '../components/artist-square'

const supporters = [
	'Diepold GmbH & Co. Bauunternehmung KG',
	'Frühlings-Apotheke',
	'Haas Hofladen GbR',
	'Hi Five Burger Bar',
	'JP Power GmbH',
	'Lasdah Lastenradverleih',
	'Galerie Lochner',
	'Luja GmbH & Co. KG',
	'NAT Neuberger Anlagen-Technik AG',
	'Optik Brönner',
	'Optik Tannek GmbH',
	'Remondis GmbH und Co. KG',
	'Sagerer Feuerlöscher',
	'Samstagskinder Ladencafé & Events',
	'Der Schreiner Rainer',
	'SFS Steuerberatungsgesellschaft mbH',
	'Siebdruck Braun',
	'Dieter Weigl Maler- und Lackierermeister',
	'Werbetechnik Limmer',
	'Vom Fass',
	'Planquadrat GmbH',
	'Zum Gasteiger',
	'Bäcker-Innung Dachau',
	'Stern Apotheke Dachau',
	'Zahnarztpraxis Dr. Höglmüller',
]

export const query = graphql`
	query {
		arena: allWpEvent(
			filter: {
				eventFields: {
					kidsProgram: { ne: true }
					event: { elemMatch: { tent: { eq: "kult Arena" } } }
				}
			}
			sort: { fields: [eventFields___event___day, eventFields___event___timeStart] }
		) {
			nodes {
				id
				title
				uri
				eventFields {
					artists {
						... on WpArtist {
							id
							title
							uri
							artistFields {
								showOnHome
							}
							featuredImage {
								node {
									id
									localFile {
										childImageSharp {
											gatsbyImageData(width: 600)
										}
									}
								}
							}
						}
					}
				}
			}
		}
		openAir: allWpEvent(
			filter: { eventFields: { event: { elemMatch: { tent: { eq: "Open Air" } } } } }
		) {
			nodes {
				id
				title
				uri
				eventFields {
					artists {
						... on WpArtist {
							id
							title
							uri
							artistFields {
								showOnHome
							}
							featuredImage {
								node {
									id
									localFile {
										childImageSharp {
											gatsbyImageData(width: 600)
										}
									}
								}
							}
						}
					}
				}
			}
		}
		kidsProgram: allWpEvent(filter: { eventFields: { kidsProgram: { eq: true } } }) {
			nodes {
				id
				title
				uri
				eventFields {
					headingId
				}
				featuredImage {
					node {
						id
						localFile {
							id
							childImageSharp {
								id
								gatsbyImageData
							}
						}
					}
				}
			}
		}
		allWpPost(limit: 4, sort: { fields: date, order: DESC }) {
			nodes {
				id
				title
				date(formatString: "D. MMMM YYYY", locale: "DE")
				excerpt
				uri
				featuredImage {
					node {
						localFile {
							childImageSharp {
								id
								gatsbyImageData(width: 300)
							}
						}
					}
				}
			}
		}
	}
`

const IndexPage = ({ data }) => {
	const { allWpPost, arena, openAir, kidsProgram } = data
	const arenaArtists = arena.nodes
		.map(node => node.eventFields.artists)
		.filter(artists => artists?.length > 0)
		.flat()
		.filter(artist => !!artist.artistFields?.showOnHome)
	const openAirArtists = openAir.nodes
		.map(node => node.eventFields.artists)
		.filter(artists => artists?.length > 0)
		.flat()
		.reduce((acc, artist) => {
			if (acc.findIndex(a => a.id === artist.id) !== -1) {
				return acc
			}
			if (!artist.featuredImage) {
				return acc
			}
			acc.push(artist)
			return acc
		}, [])
		.filter(artist => !!artist.artistFields?.showOnHome)
	const kids = kidsProgram.nodes.map(node => node)
	const posts = allWpPost.nodes

	return (
		<Layout content="fullWidth">
			<Seo title="Home" />
			<section className="max-w-2xl mx-auto mb-18">
				<H1 className="text-center mb-2">kult'21</H1>
				<p className="text-base text-center mb-8">Das Musik- und Kulturfestival</p>
				<p className="text-2xl sm:text-4xl font-semibold text-center mb-2">
					15. - 19. September 2021
				</p>
				<p className="text-center text-base mb-2">
					<a
						href="https://goo.gl/maps/DwrK5EFcRsFKsE628"
						target="_blank"
						rel="noreferrer"
						className="underline hover:text-primary"
					>
						Ludwig-Thoma-Wiese, Dachau
					</a>
				</p>
				<div className="mb-12 text-center text-base">
					<Link to="/oeffnungszeiten" className="underline hover:text-primary">
						Geländeöffnungszeiten
					</Link>
				</div>
			</section>
			<section className="mb-20">
				<Container size="wide" className="text-center mt-20 mb-10 lg:mt-40 lg:mb-20">
					<H2>kultARENA</H2>
					<p className="font-semibold text-xl text-gray-700 mb-8">Hauptzelt</p>
					<p className="max-w-2xl mx-auto text-base md:text-lg">
						Das Gelände der <strong>kultARENA</strong> hat einen{' '}
						<strong>eigenen kleinen Biergartenbereich</strong> mit Bar. Der Zutritt ist auf Personen
						beschränkt, die eine Karte für die <strong>kultARENA</strong> des jeweiligen Tages
						besitzen. Verschiedene Installationen sorgen für ein gemütliches Ambiente vor und nach
						den Veranstaltungen im Zelt.
					</p>
				</Container>
				<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 bg-black">
					{arenaArtists.map(artist => (
						<Link key={artist.id} to={artist.uri}>
							<ArtistSquare artist={artist} />
						</Link>
					))}
					<div className="aspect-w-2 aspect-h-2 block bg-primary">
						<div className="flex justify-center items-center p-8">
							<StaticImage
								src="../images/kult21-logo-true-square.png"
								width={150}
								layout="constrained"
								aspectRatio={2 / 2}
								placeholder="none"
								alt="kult21 Logo"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="mb-20 lg:mb-40">
				<Container className="text-center mt-20 mb-10 lg:mt-40 lg:mb-20">
					<H2 className="uppercase">Open Air</H2>
					<p className="font-semibold lowercase text-xl text-gray-700 mb-8">umsonst & draußen</p>

					<p className="max-w-2xl mx-auto text-base md:text-lg">
						Der <strong>OPEN AIR</strong> Bereich ist für alle Menschen kostenfrei zugänglich. Es
						gibt einen Biergarten mit Außenbühne. Hier findet ihr den Sinnesgarten, das Galeriezelt
						und die Sonderbar. Außerdem versorgen euch die{' '}
						<a
							href="https://samstagskinder.com"
							target="_blank"
							rel="noreferrer"
							className="underline"
						>
							Samstagskinder
						</a>{' '}
						mit Leckereien.{' '}
					</p>
				</Container>
				<div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-6 bg-black">
					{openAirArtists.map(artist => (
						<Link key={artist.id} to={artist.uri}>
							<ArtistSquare artist={artist} />
						</Link>
					))}
					<div className="block aspect-w-2 aspect-h-2 bg-primary">
						<div className="flex justify-center items-center p-8">
							<StaticImage
								src="../images/kult21-logo-true-square.png"
								width={150}
								layout="constrained"
								aspectRatio={2 / 2}
								placeholder="none"
								alt="kult21 Logo"
							/>
						</div>
					</div>
				</div>
				<Container className="hidden md:block mt-8 mb-16 text-2xl text-center font-bold">
					<p>…und viele Weitere!</p>
				</Container>
			</section>

			<section className="mb-20 lg:mb-40">
				<Container className="text-center mt-20 mb-10 lg:mt-40 lg:mb-20">
					<H2 className="mb-8">Kinderprogramm</H2>
					<p className="max-w-2xl mx-auto text-base md:text-lg">
						Für unsere jüngeren Besucher*innen gibt es verschiedene Kindertheater, ein Spielmobil
						auf dem Gelände und Workshops, die sich an Kinder verschiedener Altersstufen richten.
					</p>
				</Container>

				<div className="grid grid-cols-3 md:grid-cols-5 lg:grid-cols-5 bg-black">
					{kids.map(artist => (
						<Link
							key={artist.id}
							to={
								artist.eventFields.headingId
									? `/kinder#${artist.eventFields.headingId}`
									: artist.uri
							}
						>
							<ArtistSquare artist={artist} />
						</Link>
					))}
					<div className="hidden md:block aspect-w-2 aspect-h-2 bg-primary">
						<div className="flex justify-center items-center">
							<StaticImage
								src="../images/offenes-kinderprogramm.jpg"
								layout="constrained"
								aspectRatio={2 / 2}
								placeholder="none"
								alt="kult21 Logo"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="mb-20 lg:mb-40">
				<Container size="wide">
					<H2 className="text-center mb-12">News</H2>
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-8 lg:grid-cols-4 mb-12">
						{posts.map(post => {
							const featuredImage = !!post.featuredImage
							const imageData = featuredImage && getImage(post.featuredImage.node?.localFile)

							return (
								<article
									key={post.id}
									className="group cursor-pointer"
									onClick={() => navigate(post.uri)}
								>
									<GatsbyImage
										className="w-full h-48 object-cover"
										image={imageData}
										alt={`Featured image for the article "${post.title}"`}
									/>
									<p className="text-sm text-gray-700 my-2">{post.date}</p>
									<H as="h3" size={4} className="font-semibold text-xl mb-2">
										{post.title}
									</H>
									<div
										className="text-base leading-relaxed text-gray-700 mb-2"
										dangerouslySetInnerHTML={{ __html: post.excerpt }}
									/>
									<Link to={post.uri} className="group-hover:underline hover:underline">
										Weiterlesen
									</Link>
								</article>
							)
						})}
					</div>
					<div className="text-center">
						<Link to="/news" className="text-primary hover:underline">
							&raquo; Alle Beiträge ansehen &laquo;
						</Link>
					</div>
				</Container>
			</section>
			<section className="bg-secondardy py-20 mb-20 lg:mb-40 text-center">
				<Container>
					<H2 className="sr-only">Werde jetzt kult Freund*in</H2>
					<StaticImage
						src="../images/kult-freund-in.png"
						width={290}
						placeholder="none"
						alt="Werde jetzt kult Freund*in"
						className="mb-8"
					/>
					<p>
						Wir wollen unser Kulturangebot möglichst erschwinglich anbieten und arbeiten alle
						ehrenamtlich – deshalb sind wir auf Spenden und Sponsor*innen angewiesen.
					</p>
					<div className="py-8">
						<a
							href="https://www.paypal.com/donate?hosted_button_id=72UUDXNYDXDHQ"
							type="button"
							className="appearance-none inline-flex items-center px-8 py-4 border border-transparent text-lg font-semibold uppercase tracking-wide rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
							target="_blank"
							rel="noreferrer"
						>
							Jetzt spenden
						</a>
					</div>
					<p>
						Bei einer Spende von mindestens 30 Euro erhaltet ihr auf dem Festival euer persönliches
						kult-Freund*innen Paket mit tollen Überraschungen!
					</p>
				</Container>
			</section>
			<section className="px-8 text-center mb-20">
				<Container size="wide">
					<H3 className="mb-10">Mit freundlicher Unterstützung von</H3>
					<Sponsors />
					<p className="font-bold mt-20 mb-10">
						Des Weiteren bedanken wir uns herzlich bei folgenden Unterstützer*innen:
					</p>
					<div className="flex flex-wrap justify-center gap-x-5 gap-y-2">
						{supporters.map((supporter, idx, array) => (
							<span key={supporter}>{supporter}</span>
						))}
					</div>
					<p className="mt-10">
						Ihr wollt das kult’21 sponsern?{' '}
						<Link className="underline hover:text-primary" to="/sponsorinnen">
							Hier geht’s lang!
						</Link>
					</p>
				</Container>
			</section>
		</Layout>
	)
}

export default IndexPage
