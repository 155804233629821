import * as React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import PageTitle from '../components/pageTitle'
import { Facebook, Twitter, Instagram, YoutubeChannel } from '../components/icons'

const socialIcons = {
	facebook: Facebook,
	twitter: Twitter,
	instagram: Instagram,
	youtubeChannel: YoutubeChannel,
}

export const query = graphql`
	query ($id: String) {
		wpArtist(id: { eq: $id }) {
			id
			uri
			title
			content
			artistFields {
				price
				url
				youtube
				socialMedia {
					facebook
					instagram
					twitter
					youtubeChannel
				}
				event {
					... on WpEvent {
						id
						title
						eventFields {
							category
							kidsProgram
							price
							event {
								day
								fieldGroupName
								tent
								timeEnd
								timeEntry
								timeStart
							}
						}
					}
				}
			}
		}
	}
`

const WpArtist = ({ data }) => {
	const { wpArtist } = data
	const yt = wpArtist.artistFields.youtube
	const events = wpArtist.artistFields.event
	const performance = events[0].eventFields.event[0]
	const social = Object.keys(wpArtist.artistFields.socialMedia)
		.filter(key => !!wpArtist.artistFields.socialMedia[key])
		.map(key => [key, wpArtist.artistFields.socialMedia[key], socialIcons[key]])
	const originalPrice = events[0].eventFields.price
	const price = new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
	}).format(originalPrice)
	const [day, month, year] = performance.day.split('/')
	const date = new Date(year, month - 1, day).toLocaleDateString('de', {
		dateStyle: 'medium',
	})

	return (
		<Layout>
			<Seo title={wpArtist.title} path={wpArtist.uri} />
			<PageTitle>{wpArtist.title}</PageTitle>
			{social.length ? (
				<div className="flex justify-center items-center space-x-2 mb-8">
					{social.map(([key, link, Icon]) => (
						<div key={key}>
							<a href={link} target="_blank" rel="noreferrer">
								<Icon className="w-8 h-8" />
								<span className="sr-only">{key}</span>
							</a>
						</div>
					))}
				</div>
			) : null}
			<div className="mb-8 p-4 bg-gray-100">
				<div className="flex flex-wrap justify-center mb-2 text-primary">
					<span className="flex items-center space-x-2 p-2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="flex-none h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
							/>
						</svg>{' '}
						<span className="flex-auto">{date}</span>
					</span>
					<span className="flex items-center space-x-2 p-2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="flex-none h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
						<span>
							{performance.timeStart} - {performance.timeEnd} Uhr
						</span>
					</span>
					<span className="flex items-center space-x-2 p-2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
							/>
						</svg>
						<span>{originalPrice ? price : 'kostenfrei'}</span>
					</span>
				</div>

				<div className="flex justify-center space-x-2">
					<span className="font-semibold">{performance.tent}</span>
					{performance.timeEntry ? (
						<>
							<span>|</span> <span>Einlass: {performance.timeEntry}</span>
						</>
					) : null}
				</div>
			</div>
			{performance.tent === 'kult Arena' && (
				<div className="mb-8 p-4 text-white font-semibold text-center">
					<Link
						to="/tickets"
						type="button"
						className="appearance-none inline-flex items-center px-8 py-4 border border-transparent text-lg font-semibold uppercase tracking-wide rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
					>
						Jetzt Ticket kaufen
					</Link>
				</div>
			)}
			{yt && (
				<div className="aspect-w-16 aspect-h-9 mb-8">
					<iframe
						width="100%"
						height="100%"
						src={wpArtist.artistFields.youtube}
						title={`YouTube Video Player: ${wpArtist.title}`}
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
			)}
			<div className="prose prose-lg" dangerouslySetInnerHTML={{ __html: wpArtist.content }}></div>
		</Layout>
	)
}

export default WpArtist
