import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

function ArtistSquare({ artist }) {
  const featuredImage = !!artist.featuredImage
  const imageData =
    featuredImage && getImage(artist.featuredImage.node?.localFile)

  return (
    <div className="relative group">
      {imageData && (
        <div className="aspect-h-2 aspect-w-2 overflow-hidden">
          <GatsbyImage
            image={imageData}
            className="w-full filter grayscale"
            alt={artist.title}
            style={{
              position: "absolute",
            }}
          />
        </div>
      )}
      <div className="absolute top-0 left-0 right-0 bottom-0 opacity-0 group-hover:opacity-50 transition-opacity duration-150 flex bg-gradient-to-t from-black pointer-events-none"></div>
      <div className="font-heading absolute left-0 right-0 bottom-0 w-full opacity-0 group-hover:opacity-100 transition-opacity duration-150 text-white font-semibold text-center pointer-events-none truncate lg:text-xl">
        {artist.title}
      </div>
    </div>
  )
}

export default ArtistSquare
