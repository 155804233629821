import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

const sponsorsQuery = graphql`
  query {
    allWpSponsor {
      nodes {
        id
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 450, placeholder: NONE, quality: 80)
              }
            }
          }
        }
      }
    }
  }
`

const Sponsors = () => {
  const { allWpSponsor } = useStaticQuery(sponsorsQuery)
  const sponsors = allWpSponsor.nodes

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 justify-items-center">
      {sponsors.map(sponsor => {
        const image = getImage(sponsor.featuredImage.node.localFile)

        return (
          <div key={sponsor.id} className="sm:w-3/5">
            <GatsbyImage image={image} alt={sponsor.title} />
            <span className="sr-only">{sponsor.title}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Sponsors
