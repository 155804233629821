import { Link } from 'gatsby'
import React from 'react'

import { Facebook, Instagram, Twitter } from './icons'

const socialLinks = [
	{
		id: 'facebook',
		Icon: Facebook,
		label: 'Facebook',
		url: 'https://www.facebook.com/kultfestival/',
	},
	{
		id: 'instagram',
		Icon: Instagram,
		label: 'Instagram',
		url: 'https://www.instagram.com/kult_festival/',
	},
	{
		id: 'twitter',
		Icon: Twitter,
		label: 'Twitter',
		url: 'https://twitter.com/kult_festival',
	},
]

const footerMenu = [
	{
		id: 'awareness',
		label: 'Awareness',
		href: '/awareness',
	},
	{
		id: 'opening-hours',
		label: 'Öffnungszeiten',
		href: '/oeffnungszeiten',
	},
	{
		id: 'sponsors',
		label: 'Werde Sponsor*in',
		href: '/sponsorinnen',
	},
	{
		id: 'press',
		label: 'Presse',
		href: '/presse',
	},
	{
		id: 'contact',
		label: 'Kontakt',
		href: '/kontakt',
	},
	{
		id: 'agb',
		label: 'AGB',
		href: '/agb',
	},
	{
		id: 'hausordnung',
		label: 'Hausordnung',
		href: '/hausordnung',
	},
	{
		id: 'privacy',
		label: 'Datenschutzerklärung',
		href: '/datenschutzerklaerung',
	},
	{
		id: 'imprint',
		label: 'Impressum',
		href: '/impressum',
	},
]

function Footer() {
	return (
		<footer className="mx-auto overflow-hidden w-full text-white bg-footer py-12 lg:py-36">
			<div className="max-w-7xl mx-auto flex flex-col items-center">
				<nav className="flex items-center space-x-8 mb-10 lg:mb-20">
					{socialLinks.map(link => (
						<a key={link.id} href={link.url} target="_blank" rel="noreferrer">
							<link.Icon className="w-8 h-8" />
							<span className="sr-only">{link.label}</span>
						</a>
					))}
				</nav>
				<div className="mb-8 lg:mb-20 flex flex-col flex-wrap justify-center items-center space-y-4 sm:flex-row sm:space-x-10 sm:space-y-0">
					{footerMenu.map(item => (
						<Link key={item.id} className="font-semibold hover:underline" to={item.href}>
							{item.label}
						</Link>
					))}
				</div>
				<div className="text-sm">© {new Date().getFullYear()} kult'21 – jetzt e.V. Dachau</div>
			</div>
		</footer>
	)
}

export default Footer
