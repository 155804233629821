import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pageTitle"

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      uri
    }
  }
`

const WpPage = ({ data }) => {
  const { wpPage } = data
  return (
    <Layout>
      <Seo title={wpPage.title} path={wpPage.uri} />
      <PageTitle>{wpPage.title}</PageTitle>
      <article className="prose prose-lg">
        <div dangerouslySetInnerHTML={{ __html: wpPage.content }}></div>
      </article>
    </Layout>
  )
}

export default WpPage
