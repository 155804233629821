import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cx from 'classnames'

const navLinks = [
	{
		id: 'home',
		label: 'Home',
		path: '/',
	},
	{
		id: 'news',
		label: 'News',
		path: '/news',
	},
	{
		id: 'corona',
		label: 'Corona',
		path: '/corona',
	},
	{
		id: 'tickets',
		label: 'Tickets',
		path: '/tickets',
	},
	{
		id: 'program',
		label: 'Programm',
		path: '/programm',
	},
	{
		id: 'kids',
		label: 'Kinder',
		path: '/kinder',
	},
	{
		id: 'support',
		label: 'Mithelfen',
		path: '/2021/08/01/wir-suchen-noch-tatkraftige-unterstutzung',
	},
	{
		id: 'about',
		label: 'Über uns',
		path: '/ueber-uns',
	},
]

const Header = ({ siteTitle }) => (
	<header className="w-full py-6 px-8 lg:py-12 lg:px-16 bg-header bg-cover bg-center text-white">
		<div className="flex flex-col items-center">
			<div className="w-24 sm:w-52 lg:w-64">
				<Link to="/">
					<StaticImage
						src="../images/kult-21-logo.png"
						alt="kult'21 Logo"
						placeholder="none"
						layout="fullWidth"
					/>
				</Link>
			</div>
			<nav className="flex flex-wrap justify-center space-x-5 text-lg mt-6 lg:mt-12">
				{navLinks.map(link => {
					return link.external ? (
						<a
							key={link.id}
							className="hover:underline font-semibold"
							href={link.path}
							target="_blank"
							rel="noreferrer"
						>
							{link.label}
						</a>
					) : (
						<Link key={link.id} to={link.path} className="hover:underline font-semibold">
							{link.label}
						</Link>
					)
				})}
			</nav>
		</div>
	</header>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
