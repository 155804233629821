import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import H from "../components/heading"

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      date(formatString: "D. MMMM YYYY", locale: "de")
      content
      uri
      dateMeta: date
      modifiedMeta: modified
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              id
              gatsbyImageData(width: 720, quality: 90)
            }
          }
        }
      }
    }
  }
`

const WpPost = ({ data }) => {
  const { wpPost } = data
  const imagePath = wpPost.featuredImage?.node.localFile.publicURL
  const hasFeaturedImage = !!wpPost.featuredImage
  const imageData =
    hasFeaturedImage && getImage(wpPost.featuredImage.node.localFile)
  return (
    <Layout>
      <Seo
        title={wpPost.title}
        article
        path={wpPost.uri}
        imagePath={imagePath}
        meta={[
          {
            property: `article:published_time`,
            content: new Date(wpPost.dateMeta).toISOString(),
          },
          {
            property: `article:modified_time`,
            content: new Date(wpPost.modifiedMeta).toISOString(),
          },
        ]}
      />
      <article>
        <H as="h1" size={2} className="text-center mb-4">
          {wpPost.title}
        </H>
        <p className="text-sm text-center text-gray-700 mb-4">
          Veröffentlicht am: {wpPost.date}
        </p>
        {hasFeaturedImage && (
          <div className="aspect-w-16 aspect-h-9 mb-4 relative">
            <GatsbyImage
              className="w-full object-cover"
              image={imageData}
              style={{
                position: "absolute",
              }}
            />
          </div>
        )}
        <div
          className="prose prose-lg"
          dangerouslySetInnerHTML={{ __html: wpPost.content }}
        ></div>
      </article>
    </Layout>
  )
}

export default WpPost
