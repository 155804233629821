/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import cx from 'classnames'

import '@fontsource/quicksand/400.css'
import '@fontsource/quicksand/600.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, content = 'default' }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<div className="font-body text-lg text-gray-800" style={{ hyphens: 'auto' }}>
			<section className="w-full bg-red-500">
				<div className="max-w-xl m-auto text-center p-8 font-semibold text-white">
					<p>
						Du befindest dich auf der Seite des kult Festival von 2021. Unsere aktuelle Seite
						findest du hier:
					</p>
					<div className="mt-2 text-2xl">
						<a className="hover:underline" href="https://2023.kult-festival.de">
							https://2023.kult-festival.de
						</a>
					</div>
				</div>
			</section>
			<Header siteTitle={data.site.siteMetadata?.title} />
			<div className="py-12 sm:py-20">
				<main
					className={cx(
						{
							'max-w-7xl': content === 'wide',
							'max-w-3xl': content === 'default',
							'px-4 sm:px-6 lg:px-8': content !== 'fullWidth',
						},
						'mx-auto '
					)}
				>
					{children}
				</main>
			</div>
			<Footer />
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	content: PropTypes.oneOf(['default', 'wide', 'fullWidth']),
}

export default Layout
