import React from 'react'
import cx from 'classnames'

const allowedAs = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div']

function H({ as = '', size = 1, className, children, ...props }) {
	const classes = cx(
		['font-heading', 'font-bold'],
		{
			'text-4xl sm:text-6xl': size === 1,
			'text-3xl sm:text-5xl': size === 2,
			'text-2xl sm:text-4xl': size === 3,
			'text-xl sm:text-3xl': size === 4,
		},
		className
	)

	const element = allowedAs.includes(as) ? as : `h${size}`

	return React.createElement(element, { className: classes, ...props }, children)
}

const H1 = props => <H as="h1" size={1} {...props} />
const H2 = props => <H as="h2" size={2} {...props} />
const H3 = props => <H as="h3" size={3} {...props} />
const H4 = props => <H as="h4" size={4} {...props} />

export { H1, H2, H3, H4 }

export default H
