// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-corona-js": () => import("./../../../src/pages/corona.js" /* webpackChunkName: "component---src-pages-corona-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kinder-js": () => import("./../../../src/pages/kinder.js" /* webpackChunkName: "component---src-pages-kinder-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-oeffnungszeiten-js": () => import("./../../../src/pages/oeffnungszeiten.js" /* webpackChunkName: "component---src-pages-oeffnungszeiten-js" */),
  "component---src-pages-programm-js": () => import("./../../../src/pages/programm.js" /* webpackChunkName: "component---src-pages-programm-js" */),
  "component---src-pages-sponsorinnen-js": () => import("./../../../src/pages/sponsorinnen.js" /* webpackChunkName: "component---src-pages-sponsorinnen-js" */),
  "component---src-pages-tickets-js": () => import("./../../../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-templates-wp-artist-js": () => import("./../../../src/templates/wp-artist.js" /* webpackChunkName: "component---src-templates-wp-artist-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/wp-page.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/wp-post.js" /* webpackChunkName: "component---src-templates-wp-post-js" */),
  "component---src-templates-wp-workshop-js": () => import("./../../../src/templates/wp-workshop.js" /* webpackChunkName: "component---src-templates-wp-workshop-js" */)
}

