import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import PageTitle from '../components/pageTitle'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const query = graphql`
	query ($id: String) {
		wpEvent(id: { eq: $id }) {
			id
			title
			content
			uri
			eventFields {
				category
				event {
					day
					tent
					timeEnd
					timeEntry
					timeStart
				}
			}
			featuredImage {
				node {
					caption
					localFile {
						publicURL
						childImageSharp {
							id
							gatsbyImageData(width: 640)
						}
					}
				}
			}
		}
	}
`

const WpWorkshop = ({ data }) => {
	const { wpEvent: workshop } = data
	const { event: events, category } = workshop.eventFields

	const hasFeaturedImage = !!workshop.featuredImage
	const imageData = hasFeaturedImage && getImage(workshop.featuredImage.node.localFile)

	const hasEvents = Array.isArray(events) && events.length > 0

	return (
		<Layout>
			<Seo title={workshop.title} path={workshop.uri} />
			{category !== 'Sonstiges' && (
				<div className="text-gray-700 text-2xl text-center mb-4">{category}</div>
			)}
			<PageTitle>{workshop.title}</PageTitle>
			{hasEvents
				? events.map(event => {
						const [day, month, year] = event.day.split('/')
						const date = new Date(year, month - 1, day).toLocaleDateString('de', {
							dateStyle: 'medium',
						})
						return (
							<div key={event.day} className="mb-4 p-2 bg-gray-100">
								<div className="flex flex-wrap justify-center text-primary">
									<span className="flex items-center space-x-2 p-2">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="flex-none h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
											/>
										</svg>{' '}
										<span className="flex-auto">{date}</span>
									</span>
									<span className="flex items-center space-x-2 p-2">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="flex-none h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
										<span>
											{event.timeStart} - {event.timeEnd} Uhr
										</span>
									</span>
									<span className="flex items-center space-x-2 p-2">
										<span className="font-semibold">{event.tent}</span>
									</span>
								</div>
							</div>
						)
				  })
				: null}
			{hasFeaturedImage && (
				<>
					<div className="aspect-w-16 aspect-h-9 mb-4 relative">
						<GatsbyImage
							className="w-full object-cover"
							image={imageData}
							style={{
								position: 'absolute',
							}}
							alt={`Featured image for workshop "${workshop.title}"`}
						/>
					</div>
					<div
						className="text-center text-sm text-gray-700"
						dangerouslySetInnerHTML={{
							__html: workshop.featuredImage.node.caption,
						}}
					/>
				</>
			)}
			<article className="prose prose-lg">
				<div dangerouslySetInnerHTML={{ __html: workshop.content }}></div>
			</article>
		</Layout>
	)
}

export default WpWorkshop
