import { graphql, Link } from 'gatsby'
import React from 'react'
import { groupBy, sortBy } from 'lodash'
import Layout from '../components/layout'
import PageTitle from '../components/pageTitle'
import Seo from '../components/seo'
import { H2 } from '../components/heading'
import cx from 'classnames'
import Container from '../components/container'
import Kids from '../components/kids'

export const query = graphql`
	query {
		allWpEvent(sort: { fields: [eventFields___event___day, eventFields___event___timeStart] }) {
			nodes {
				id
				title
				uri
				content
				excerpt
				eventFields {
					category
					kidsProgram
					headingId
					event {
						day
						tent
						timeEnd
						timeEntry
						timeStart
					}
					artists {
						... on WpArtist {
							id
							title
							uri
						}
					}
				}
			}
		}
	}
`

const EventCard = ({ title, tent, timeStart, timeEnd, kidsProgram = false, content = '' }) => {
	const location =
		tent === 'kult Arena' ? 'kultARENA' : tent === 'Workshopzelt' ? 'Workshopzelt' : 'OPEN AIR'
	return (
		<div className={cx('grid grid-cols-3 p-2')}>
			<div
				className={cx({
					'col-span-3': !kidsProgram,
					'col-span-2': kidsProgram,
				})}
			>
				<div className="font-bold">{title}</div>
				<div className="">
					{timeStart} - {timeEnd}
				</div>
				<div className="block sm:hidden">{location}</div>
				{typeof content === 'string' ? (
					<div className="text-base" dangerouslySetInnerHTML={{ __html: content }} />
				) : (
					content
				)}
			</div>
			{kidsProgram ? <Kids className="ml-auto w-10" /> : null}
		</div>
	)
}

const Program = ({ data }) => {
	const events = data.allWpEvent.nodes.reduce((events, current) => {
		const isSingleEvent = current.eventFields.event.length === 1
		if (isSingleEvent) {
			events.push(current)
			return events
		}
		const multipleEvents = current.eventFields.event.map(event => {
			return Object.assign({}, current, {
				eventFields: {
					...current.eventFields,
					event: [event],
				},
			})
		})
		return events.concat(multipleEvents)
	}, [])
	const byDay = groupBy(events, event => event.eventFields.event[0].day)
	const byDayByTent = sortBy(
		Object.keys(byDay).map(strDay => {
			const [day, month, year] = strDay.split('/')
			const date = new Date(year, month - 1, day).toLocaleDateString('de', {
				weekday: 'long',
				day: '2-digit',
				month: '2-digit',
			})
			const byTent = groupBy(
				sortBy(byDay[strDay], event => event.eventFields.event[0].timeStart),
				event => {
					if (
						['Bildungsprogramm', 'Sonstiges', 'Kunstperformance'].includes(
							event.eventFields.category
						)
					) {
						return 'Workshopzelt'
					}
					return event.eventFields.event[0].tent
				}
			)
			return { key: strDay, formatted: date, byTent }
		}),
		day => day.key
	)

	return (
		<Layout content="wide">
			<Seo title="Gesamtprogramm" path="/programm/" />
			<PageTitle>Gesamtprogramm</PageTitle>
			<Container className="mb-10 sm:mb-20 text-center">
				<p className="mb-2">
					Ihr findet hier unser gesamtes <strong>kult'21</strong> Programm.
				</p>
				<p className="mb-8">
					<Link to="/oeffnungszeiten" className="underline">
						Hier geht's zu unseren Geländeöffnungszeiten
					</Link>
				</p>
			</Container>
			<div className="grid sm:grid-cols-7 mb-4 p-4 gap-8">
				<div className="hidden sm:block sm:col-start-2 sm:col-span-2">
					<div className="text-center space-y-2">
						<H2 size={4}>kultARENA</H2>
						<Link
							to="/tickets"
							className="inline-flex items-center px-4 py-2 border border-transparent text-base font-bold rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
						>
							Karten kaufen
						</Link>
					</div>
				</div>
				<div className="hidden sm:block sm:col-span-2">
					<div className="text-center">
						<H2 size={4}>OPEN AIR</H2>
						<p>umsonst & draußen</p>
					</div>
				</div>
				<div className="hidden sm:block sm:col-span-2">
					<div className="text-center">
						<H2 size={4}>Workshops</H2>
						<p>und Bildungsprogramm</p>
					</div>
				</div>
				{byDayByTent.map(({ key, formatted, byTent }) => (
					<React.Fragment key={key}>
						<div className="text-center sm:text-left">
							<h3 className="text-xl font-bold font-heading">{formatted}</h3>
						</div>
						<div className="flex flex-col gap-2 sm:col-span-2">
							{byTent['kult Arena'].map(event => {
								const { title, content, eventFields } = event
								const { kidsProgram, headingId } = eventFields
								const { timeStart, timeEnd, tent } = eventFields.event[0]

								const to = kidsProgram ? `/kinder#${headingId}` : eventFields.artists?.[0].uri
								const Wrapper = props => (to ? <Link {...props} /> : <div {...props} />)

								return (
									<Wrapper
										key={event.id}
										to={to}
										className={cx('bg-yellow-100 ', {
											'hover:bg-yellow-200': !!to,
										})}
									>
										<EventCard
											title={title}
											timeStart={timeStart}
											timeEnd={timeEnd}
											tent={tent}
											kidsProgram={!!kidsProgram}
											content={content}
										/>
									</Wrapper>
								)
							})}
						</div>
						<div className="flex flex-col gap-2 sm:col-span-2">
							{byTent['Open Air'].map(event => {
								const { title, content, eventFields } = event
								const { kidsProgram, artists } = eventFields
								const { timeStart, timeEnd, tent } = eventFields.event[0]

								const hasArtists = Array.isArray(artists) && artists.length > 0
								const hasSeveralArtists = hasArtists && artists.length > 1
								const to = hasArtists ? artists[0].uri : null
								const Wrapper = props =>
									to && !hasSeveralArtists ? <Link {...props} /> : <div {...props} />

								const richContent = hasSeveralArtists
									? artists.map((artist, index, array) => (
											<React.Fragment key={artist.id}>
												<Link to={artist.uri} key={artist.id} className="hover:underline">
													{artist.title}
												</Link>
												{index !== array.length - 1 ? ', ' : null}
											</React.Fragment>
									  ))
									: content

								return (
									<Wrapper
										to={to}
										key={event.id}
										className={cx('bg-purple-100', {
											'hover:bg-purple-200': !!to && !hasSeveralArtists,
										})}
									>
										<EventCard
											title={title}
											timeStart={timeStart}
											timeEnd={timeEnd}
											tent={tent}
											kidsProgram={!!kidsProgram}
											content={richContent}
										/>
									</Wrapper>
								)
							})}
						</div>
						<div className="flex flex-col gap-2 sm:col-span-2">
							{Array.isArray(byTent['Workshopzelt']) &&
								byTent['Workshopzelt'].map(event => {
									const { id, title, excerpt, uri, eventFields } = event
									const { category, kidsProgram } = eventFields
									const { timeStart, timeEnd, tent } = eventFields.event[0]

									const Wrapper = ({ to, ...rest }) =>
										event.content ? <Link to={to} {...rest} /> : <div {...rest} />

									const content = category === 'Bildungsprogramm' ? excerpt : category

									return (
										<Wrapper
											key={id}
											to={uri}
											className={cx({
												'bg-blue-100 hover:bg-blue-200': category === 'Workshop',
												'bg-pink-100 hover:bg-pink-200': [
													'Workshop (offen)',
													'Kunstperformance',
												].includes(category),
												'bg-green-100 hover:bg-green-200': category === 'Bildungsprogramm',
												'bg-indigo-100 hover:bg-indigo-200': category === 'Sonstiges',
											})}
										>
											<EventCard
												key={id}
												title={title}
												timeStart={timeStart}
												timeEnd={timeEnd}
												tent={tent}
												kidsProgram={!!kidsProgram}
												content={content}
											/>
										</Wrapper>
									)
								})}
						</div>
					</React.Fragment>
				))}
			</div>
		</Layout>
	)
}

export default Program
