import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Container from '../components/container'
import PageTitle from '../components/pageTitle'
import { H2 } from '../components/heading'

const Kinder = () => {
	return (
		<Layout content="fullWidth">
			<Seo title="Kinderprogramm" path="/kinder/" />
			<PageTitle>Kinderprogramm</PageTitle>
			<section className="mb-20">
				<Container>
					<div className="prose prose-lg mb-8">
						<p className="text-center">
							Das <strong>kult'21</strong> ist ein Festival für Jung und Alt! Hier findet ihr unser
							Kinderprogramm, das wir euch in Kooperation mit dem{' '}
							<strong>TheaterTage Dachau e.V</strong> darbieten.
						</p>
					</div>
					<div className="flex flex-col space-y-2 sm:space-x-2 sm:space-y-0 justify-center sm:flex-row justify-items-center">
						<a
							className="px-4 py-2 uppercase font-semibold text-base text-center hover:underline bg-purple-50"
							href="#daheim-in-der-welt"
						>
							Daheim in der Welt
						</a>
						<a
							className="px-4 py-2 uppercase font-semibold text-base text-center hover:underline bg-purple-50"
							href="#das-lied-der-grille"
						>
							Das Lied der Grille
						</a>
						<a
							className="px-4 py-2 uppercase font-semibold text-base text-center hover:underline bg-purple-50"
							href="#emil-und-die-detektive"
						>
							Emil und die Detektive
						</a>
					</div>
				</Container>
			</section>
			<section className="pb-20">
				<Container>
					<article id="daheim-in-der-welt">
						<div className="mb-12 text-center">
							<H2 className="leading-relaxed">Daheim in der Welt</H2>
							<p className="uppercase text-gray-600 text-base font-semibold">Thalias Kompagnons</p>
						</div>
						<div className="mb-8 p-8 bg-yellow-100 text-center">
							<p className="text-xl font-semibold mb-2">
								Donnerstag, 16.09. um 15:15 Uhr und 16:15 Uhr (Dauer 30 Min.).
							</p>
							<p className="mb-4">kult Arena</p>
							<p>
								Ab 4 Jahre / <strong>6€</strong> / Tickets ab 07.06.
							</p>
						</div>
						<div className="flex-auto">
							<div className="prose prose-lg">
								<p>
									Ein Zuhause braucht jeder. <br />
									Einen Ort, wo man hingehört. <br />
									Menschen, bei denen man dazugehört. <br />
									Einen Platz, wo man sich geborgen fühlt.
								</p>
								<p>
									Manchmal muss man sich so einen Platz erst suchen. Oder erschaffen. Allein oder
									gemeinsam mit anderen. So geht es auch den kleinen Sachen, die der Theaterspieler
									gefunden und geschenkt bekommen hat. Strandsteine, ein Schneckenhaus, ein
									zierliches Puppenkissen
								</p>
								<p>
									Sie alle bekommen auf dem Theaterhocker Gelegenheit, für sich und füreinander zu
									sorgen, gemeinsam eine Welt zu entdecken und in ihr heimisch zu werden.
								</p>
								<blockquote>
									<p>
										Auf einem kleinen Tisch entstehen mit kleinen Objekten wie Steinen, Postkarten
										oder Spielzeughäuschen Geschichten darüber, wie schön es in der Welt sein kann,
										voller Wärme, Humor und Konzentration, die das Publikum in den Bann ziehen.
									</p>
									<footer>
										<cite> – Die deutsche Bühne</cite>
									</footer>
								</blockquote>
								<p>
									Idee und Spiel: <strong>Tristan Vogt</strong>
									<br />
									Mitarbeit: <strong>Iwona Jera, Joachim Torbahn</strong>
								</p>
								<p>
									In Kooperation mit <strong>TheaterTage Dachau e.V.</strong>
									<br />
									Mit freundlicher Unterstützung der <strong>Frühlingsapotheke</strong>
								</p>
							</div>
						</div>
					</article>
				</Container>
			</section>
			<section className="py-20 bg-primary text-gray-50">
				<Container>
					<article id="das-lied-der-grille" className="">
						<div className="mb-12 text-center">
							<H2 className="leading-relaxed">Das Lied der Grille</H2>
							<p className="uppercase text-gray-200 text-base font-semibold">Theater Die Exen</p>
						</div>
						<div className="mb-8 p-8 bg-gray-100 text-gray-700 text-center">
							<p className="text-xl font-semibold mb-2">Samstag, 18.9. um 10:30 Uhr</p>
							<p className="mb-4">kult Arena</p>
							<p>
								Ab 5 Jahre / <strong>7€</strong> / Tickets ab 07.06.
							</p>
						</div>
						<div className="flex space-x-4 justify-center mb-2">
							<StaticImage src="../images/grille-1.jpg" width={380} alt="Das Lied der Grille 1" />
							<StaticImage src="../images/grille-2.jpg" width={380} alt="Das Lied der Grille 2" />
						</div>
						<div className="mb-8 text-sm">Fotos von Dirk Wildt</div>
						<div className="flex-auto">
							<div className="prose prose-lg prose-dark">
								<p>
									Die Sonne scheint, die Wiese blüht. Alles könnte so schön sein, wäre da nicht
									diese endlose Plackerei: Sammeln, sammeln, sammeln ist das Motto der fleissigen
									Wiesenbewohner. Die Vorratskammern müssen bis zum Winter voll sein. Doch dann
									klingt eine Melodie über die Wiese und allen wird es ganz leicht und heiter zu
									Mute. Was einer Grille mit ihrer Geige gelingt, ist kaum zu fassen: Alle plaudern,
									lachen und tanzen. Doch dann kommt er – der Winter. Und die Grille hat nichts
									gesammelt…
								</p>
								<p>
									Ein bewohntes Klavier erzählt die Geschichte vom Fiedeln oder Vorsorgen, von
									schönen Künsten, Plackerei, leeren Mägen und vollen Speisekammern, von einem
									fetten Sommer und einem eisigen Winter. Und einem Freund…
								</p>
								<p>Erleben Sie ein absolut sehenswertes Theater mit Puppen, Licht und Schatten.</p>
								<p>
									Regie: <strong>Kristine Stahl</strong>
									<br />
									Idee und Spiel: <strong>Annika Pilstl</strong>
								</p>
								<p>
									In Kooperation mit <strong>TheaterTage Dachau e.V.</strong>
									<br />
									Mit freundlicher Unterstützung von <strong>Trachtenmode Schiela</strong>
								</p>
							</div>
						</div>
					</article>
				</Container>
			</section>
			<section className="py-20">
				<Container>
					<article id="emil-und-die-detektive">
						<div className="mb-12 text-center">
							<H2 className="leading-relaxed">Emil und die Detektive</H2>
							<p className="uppercase text-gray-600 text-base font-semibold">
								Greulmüllersche HörspielManufaktur
							</p>
						</div>
						<div className="mb-8 p-8 bg-yellow-100 text-center">
							<p className="text-xl font-semibold mb-2">
								Sonntag, 19.09. um 10:00 Uhr und 11:45 Uhr
							</p>
							<p className="mb-4">kult Arena</p>
							<p>
								Ab 7 Jahre / <strong>8€</strong> / Tickets ab 07.06.
							</p>
						</div>
						<div className="flex space-x-4 justify-center mb-8">
							<StaticImage src="../images/emil-1.jpg" width={380} alt="Emil und die Detektive 1" />
							<StaticImage src="../images/emil-2.jpg" width={380} alt="Emil und die Detektive 2" />
						</div>
						<div className="flex-auto">
							<div className="prose prose-lg">
								<p>
									Der 12-jährige Emil Tischbein fährt zum ersten Mal mit dem Zug nach Berlin. Seine
									Mutter hat ihm Geld mitgegeben und dieses wird Emil im Zug gestohlen. In Berlin
									angekommen, verfolgt er den Dieb. Zum Glück lernt Emil den Jungen Gustav die Hupe
									kennen. Gustav und seine Freunde unterstützen Emil bei der Verfolgungsjagd quer
									durch Berlin. Nach vielen Abenteuern gelingt es ihnen den Dieb zur Stecke bringen.
								</p>
								<p>
									Den Akteuren Sebastian Hofmüller und Greulix Schrank gelingt es aus dem Klassiker
									von Erich Kästner ein Hörspielabenteuer live zu inszenieren. Kein Theaterstück,
									keine Lesung, kein Film. Zu zweit spielen, sprechen und musizieren sie alle
									Rollen, Atmosphären, Gefahren und auch das Happy End des Abenteuers.
								</p>
								<p>
									Ein Live-Hörspielabenteuer ist etwas ganz besonderes, weil jeder Zuschauer beim
									Zuhören und Zusehen einen eigenen Film in seinem Kopf entstehen lässt. Jeder
									sollte die Erfahrung machen, wie viel Vergnügen diese Inszenierung bereitet.
								</p>
								<p>
									Idee und Spiel: <strong>Sebastian Hofmüller</strong> und{' '}
									<strong>Greulix Schrank</strong>
								</p>
								<p>
									In Kooperation mit <strong>TheaterTage Dachau e.V.</strong>
									<br />
									Mit freundlicher Unterstützung: <strong>Naturkostinsel</strong>
								</p>
							</div>
						</div>
					</article>
				</Container>
			</section>
		</Layout>
	)
}

export default Kinder
