import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/pageTitle'
import { StaticImage } from 'gatsby-plugin-image'
import H from '../components/heading'
import { Link } from 'gatsby'

const Tickets = () => {
	return (
		<Layout>
			<PageTitle>Abendkasse</PageTitle>
			<p className="mb-12">
				Der Online Kartenvorverkauf für das <strong>kult'21</strong> ist vorbei. Wir verkaufen
				Karten für die Veranstaltungen in der <strong>kultARENA</strong> aber weiterhin über unsere
				Abendkasse!
			</p>
			<H as="h3" size={4} className="mb-4">
				Öffnungszeiten Ticketverkauf
			</H>
			<div className="grid grid-cols-2 gap-y-2 gap-x-4 mb-8">
				<div>
					<span className="hidden md:inline">Dienstag, </span> 14.09.
				</div>
				<div>
					<strong>16:00</strong> bis <strong>20:00</strong> Uhr
				</div>
				<div>
					<span className="hidden md:inline">Mittwoch, </span>15.09. bis{' '}
					<span className="hidden md:inline">Freitag, </span>17.09.
				</div>
				<div>
					<strong>16:00</strong> bis <strong>22:00</strong> Uhr
				</div>
				<div>
					<span className="hidden md:inline">Samstag, </span> 18.09.
				</div>
				<div>
					<strong>13:00</strong> bis <strong>22:00</strong> Uhr
				</div>
				<div>
					<span className="hidden md:inline">Sonntag, </span>19.09.
				</div>
				<div>
					<strong>13:00</strong> bis <strong>20:30</strong> Uhr
				</div>
			</div>
			<p className="mb-4">
				<span className="font-bold text-pink-600">Hinweis</span>: Karten für unsere{' '}
				<strong>
					<Link to="/kinder" className="underline hover:text-primary">
						Kinderveranstaltungen
					</Link>
				</strong>{' '}
				in der kultARENA können jeweils <strong>außerhalb der regulären Kassenzeiten</strong>{' '}
				zusätzlich ab einer Stunde vor Einlass der Veranstaltung erworben werden.
			</p>
			<p className="mb-4">
				An der Abendkasse akzeptieren wir <strong>ausschließlich Barzahlung</strong>!
			</p>
			<p className="mb-12">
				<Link to="/oeffnungszeiten" className="underline">
					Hier geht's zu unseren Geländeöffnungszeiten
				</Link>
			</p>
			<StaticImage src="../images/abendkasse.png" alt="kult'21 Abendkasse" />
		</Layout>
	)
}

export default Tickets
