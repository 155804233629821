import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="prose prose-lg text-center">
      <h1>Nichts gefunden!</h1>
      <p>Die angeforderte Seite existiert leider nicht… :(</p>
    </div>
  </Layout>
)

export default NotFoundPage
