import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout.js"
import PageTitle from "../components/pageTitle.js"
import Seo from "../components/seo.js"

const Sponsorinnen = () => {
  return (
    <Layout>
      <Seo title="Werde Sponsor*in" path="/sponsorinnen/" />
      <PageTitle>Werde Sponsor*in</PageTitle>
      <article className="prose prose-lg">
        <p>
          <a href="werde-sponsor-in.pdf">
            Wissenswertes für Sponsor*innen und Unterstützer*innen (PDF)
          </a>
        </p>
        <p>
          Haben Sie schon einmal vom legendären Dachauer kult-Festival aus den
          90er Jahren gehört ? Vor über 20 Jahren fand in Dachau dieses Kultur-
          und Zeltfestival im Süden der Stadt am Stadtweiher zum letzten Mal
          statt. Es dauerte sieben Tage und bot ein vielfältiges und buntes
          Veranstaltungsprogramm für Menschen jeden Alters. Rund 20.000 Personen
          besuchten jährlich dieses Kleinkunstfestival – zuletzt im Jahr 2000.
        </p>
        <p>
          2021 wollen wir diese Legende wiederbeleben … und zwar zentral auf der
          Ludwig-Thoma-Wiese, am Fuße der Dachauer Altstadt. Wir, das ist der{" "}
          <strong>Jetzt e.V.</strong> Dachau als Trägerverein und
          kult-Mitbegründer in den Neunzigern, in Kooperation mit vielen jungen
          Dachauer Vereinen und Gruppierungen, wie dem{" "}
          <strong>
            Freiraum e.V., Move Together e.V., Outer Circle, Elemental Wave,
            Schere-Stein-Papier e.V., Oktagon und dem Runder Tisch gegen
            Rassismus e.V., der Kreisjugendring Dachau, die Dachauer TheaterTage
            e.V., das Franziskuswerk Schönbrunn gGmbH, der Bund Naturschutz e.V.
            sowie die Künstlervereinigung Dachau e.V.{" "}
          </strong>
          gehören zu unseren Kooperationspartnern.
        </p>

        <p>
          Diese bunte und kompetente Mischung von Kulturschaffenden, die schon
          seit Jahren das Dachauer Kulturleben entscheidend mitprägen, haben
          sich nun zusammengetan und planen –{" "}
          <strong>ehrenamtlich und nicht gewinnorientiert</strong> – von{" "}
          <strong>Mittwoch, 15.09. bis Sonntag, 19.09.2021</strong> eine
          Neuauflage des Zeltfestival mit täglichem Programm von 10 bis 23 Uhr –
          das <strong>kult’21</strong>.
        </p>

        <p>
          Freuen Sie sich mit uns auf ein großes Kulturereignis für Jung und
          Alt, auf ein fröhliches Fest der Toleranz, der Nachhaltigkeit und der
          Integration. An insgesamt fünf Veranstaltungstagen planen wir ein
          breitgefächertes Programm mit Angeboten für Kinder von den Dachauer
          TheaterTagen, jeweils einen Konzertabend mit Dachauer und
          lateinamerikanischen Bands, mit klassischer Musik, Reggae und
          Kabarett.
        </p>

        <p>
          Natürlich kämpfen auch wir tagtäglich mit der Unsicherheit durch die
          Corona-Pandemie und planen im Moment noch mit angezogener Handbremse.
          So ist das genannte Programm zunächst auf die kult-Arena reduziert.
          Das vereinfacht die Planungen für ein sicheres Hygienekonzept, denn
          alle Beteiligten haben es sich zur Aufgabe gemacht, der völlig
          brachliegenden Kultur- und Musikszene unter die Arme zu greifen und
          die Durchführung von kult’21 auf jeden Fall und in dieser
          verkleinerten Version zu sichern. Hierbei werden für alle
          Veranstaltungen in der kult-Arena moderate Eintrittspreise erhoben.{" "}
          <strong>Der Vorverkauf beginnt am 7. Juni 2021</strong>.
        </p>

        <p>
          Je nach Möglichkeiten und gesetzlichen Vorgaben im September haben wir
          parallel einen viel größeren Plan in der Tasche: Im Optimalfall bieten
          wir zusätzlich einen für alle Besucher kostenlosen Aussenbereich. Dort
          gibt’s auf der Open-Air-Bühne Livemusik unterschiedlicher
          Stilrichtungen. Für kulinarische Genüsse auf dem Gelände sorgen
          Food-Trucks. Zudem sind interessante Workshops zu den Themen
          Nachhaltigkeit, Umweltschutz und Ernährung geplant. Die mitwirkenden
          Vereine sind trotz der fehlenden Planungssicherheit hochmotiviert und
          bereiten auch Infostände zu den Themen Kreativität und demokratische
          Bildung sowie viele Mitmachmöglichkeiten vor. Die legendäre Sonderbar
          tüftelt zusammen mit der KVD an einem spannenden Konzept mit Kunst und
          Lesungen, um das kult-Festival 21 wieder zu einem unvergesslichen
          Highlight für die Region Dachau zu machen.
        </p>

        <p>
          Die anfallenden Kosten können wir nicht alleine durch Förder-,
          Eintrittsgelder und ehrenamtliches Engagement decken. Helfen Sie uns,
          die Kosten der so wichtigen Unterstützung für alle, Künstler
          <em>innen, Techniker</em>innen und Zeltverleiher
          <em>
            innen zu stemmen – und den vielen kulturentwöhnten Menschen in
            unserer Region ein gelungenes kult‘21 zu präsentieren.
          </em>
        </p>

        <p>
          <strong>Wir zählen auf Sie.</strong>
        </p>
      </article>
    </Layout>
  )
}

export default Sponsorinnen
