import React from "react"
import { H1} from "./heading"

const PageTitle = ({ children }) => {
  return (
    <H1 className="text-center mb-8 sm:mb-16">
      {children}
    </H1>
  )
}

export default PageTitle
